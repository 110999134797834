import { APPLY_COUPON, COUPON_ERROR, ALL_COUPONS} from "./actionTypes";

const initialState = {
	available_restaurant_coupons:[],
	coupon: [],
	coupon_error: "",
};

export default function(state = initialState, action) {
	switch (action.type) {
		case APPLY_COUPON:
			return { ...state, coupon: action.payload };
		case ALL_COUPONS:
				return { ...state, available_restaurant_coupons: action.payload };
		case COUPON_ERROR:
			return { ...state, coupon_error: action.payload };
		default:
			return state;
	}
}
