// Prod Servers
export const WEBSITE_URL = "https://v2.pikit.in";
export const IMG_URL = "https://v2.pikit.in";

// UAT Servers

// export const WEBSITE_URL = "https://uat-cust-app-back.pikit.in";
// export const IMG_URL = "https://uat-cust-app-back.pikit.in";


// Dev Servers
// export const WEBSITE_URL = "http://127.0.0.1:8000";
// export const IMG_URL = "http://127.0.0.1:8000";
